.suggestions {
	@include outer-gutters;
	padding-top: 4rem;
	padding-bottom: 4rem;
	background-color: var(--theme-color--darker);
	h2 {
		color: white;
	}
	ul {
		list-style-type: none;
		display: flex;
		gap: 4rem;
		padding: 0;
		margin: 0;
		flex-wrap: wrap;

		li a {
			display: flex;
			flex-direction: column;
			justify-content: flex-end;
			background-color: white;
			background-size: cover;
			background-repeat: no-repeat;
			background-position: center;
			width: 375px;
			height: 200px;
			padding: 1rem;
			border-radius: 4px;
			@include shadow();
			transition: transform 400ms ease-in-out;
			&:hover {
				transform: scale(102.5%);
			}
			h3 {
				margin-bottom: 0;
				color: white;
				font-size: calc(var(--font-size--default) * 1.15);
			}
			div {
				color: white;
				font-size: var(--font-size--default);
			}
		}
	}
}
