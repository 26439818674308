.alert {
	padding: 0.75rem 1rem;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 2rem;
	color: var(--dark-gray);
	background-color: var(--theme-color--alert);

	p {
		margin-bottom: 0;
		font-weight: 600;
		font-size: calc(var(--font-size--default) * 1.1);
	}
	a {
		display: inline-block;
		border: 1px solid var(--dark-gray);
		border-radius: 8px;
		padding: 0.5rem 1rem;
		color: var(--dark-gray);
	}
}
