@import url("https://fonts.googleapis.com/css?family=Fira+Sans:400,500,600,700&display=swap");
@mixin sans-serif() {
	font-family: "Fira Sans", sans-serif;
}

@mixin outer_gutters() {
	padding-left: 5vw;
	padding-right: 5vw;
	@media screen and (min-width: 768px) {
		padding-left: 2.5vw;
		padding-right: 2.5vw;
	}
	@media screen and (min-width: 1441px) {
		padding-left: 7.5vw;
		padding-right: 7.5vw;
	}
}

@mixin container {
	max-width: 90vw;
	margin: 0 auto;

	@media screen and (min-width: 1400px) {
		max-width: 1200px;
	}
}

@mixin sans-serif() {
	font-family: "Fira Sans", serif;
}

@mixin shadow {
	box-shadow: 0 0.063em 0.313em 0 rgba(0, 0, 0, 0.07), 0 0.438em 1.063em 0 rgba(0, 0, 0, 0.1);
}

@mixin primary-button {
	font-size: var(--font-size--default);
	font-weight: bold;
	background-color: var(--theme-color--dark);
	padding: 0.5rem 1rem;
	display: inline-block;
	color: white;
	border-radius: 2px;
	text-align: center;
	&:hover {
		background-color: var(--theme-color);
	}
}

@mixin secondary-button {
	font-size: var(--font-size--default);
	font-weight: bold;
	background-color: var(--mid-gray);
	padding: 0.5rem 1rem;
	display: inline-block;
	border-radius: 2px;
	color: white;
	text-align: center;

	&:hover {
		background-color: var(--theme-color--darker);
	}
}
