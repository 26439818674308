.main-nav {
	padding: 1rem 1rem;
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-wrap: wrap;
	@media screen and (min-width: 1024px) {
		padding: 3rem 2rem;
	}
	&__logo {
		display: flex;
		align-items: center;
		gap: 2rem;
		img {
			max-width: 150px;
		}
	}
	&__text {
		font-weight: 500;
		text-transform: uppercase;
		color: white;
		padding-left: 2.5rem;
		margin-left: 1rem;
		border-left: 2px solid white;
		font-size: var(--font-size--default);
	}
	&__links {
		display: flex;
		gap: 2rem;
		align-items: center;
	}
	&__links a {
		font-weight: 600;
		color: white;
		&:hover {
			color: var(--theme-color--light);
		}
	}
	.abi-logo-icon {
		display: none;
		max-width: 35px;
		position: relative;
		margin-right: 2rem;
		@media screen and (min-width: 1024px) {
			display: block;
		}
		&:after {
			display: block;
			content: "";
			position: absolute;
			right: -2.25rem;
			top: -0.25rem;
			width: 2px;
			height: 40px;
			background-color: white;
		}
	}
	.register {
		padding: 0.5rem 1.5rem;
		border-radius: 4px;
		background-color: white;
		color: var(--theme-color--dark);
		&:hover {
			background-color: var(--theme-color--secondary);
			color: white;
		}
	}
}
