@import "./_mixins";

:root {
	--theme-color: #172438;
	--theme-color--light: #7195d3;
	--theme-color--dark: #182438;
	--theme-color--darker: #111a2c;
	--theme-color--secondary: #1b90c7;
	--theme-color--secondary--dark: #329a7c;
	--dark-gray: #171718;
	--mid-gray: #38383a;
	--light-gray: #a7abaf;
	--theme-color--alert: #eede28;
}

*,
*::before,
*::after {
	box-sizing: border-box;
}

html {
	scroll-padding: 120px 0 0 0;
	scroll-behavior: smooth;
}
ul[class],
ol[class] {
	padding: 0;
}

body,
h1,
h2,
h3,
h4,
p,
li,
figure,
figcaption,
blockquote,
dl,
dd {
	margin: 0;
}

body {
	position: relative;
	min-height: 100vh;
	text-rendering: optimizeSpeed;
	line-height: 1.5;
	color: var(--black);
	background-color: var(--theme-color--dark);
	font-size: var(--font-size--default);
	@include sans-serif();
}

ul[class],
ol[class] {
	list-style: none;
	margin-top: 0;
}

a:not([class]) {
	text-decoration-skip-ink: auto;
}

a:hover {
	text-decoration: none;
	color: var(--theme-color--darker);
}

img {
	max-width: 100%;
	display: block;
}

input,
button,
textarea,
select {
	font: inherit;
}

@media (prefers-reduced-motion: reduce) {
	* {
		animation-duration: 0.01ms !important;
		animation-iteration-count: 1 !important;
		transition-duration: 0.01ms !important;
		scroll-behavior: auto !important;
	}
}

@import "_type";
@import "_alert";
@import "_nav";
@import "_event";
@import "_details";
@import "_sponsors";
@import "_suggestions";
@import "_footer";
@import "_modal";
