.sponsors {
	background-color: white;
	@include outer-gutters;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	gap: 2rem;
	padding-top: 2rem;
	padding-bottom: 2rem;
	> div {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		gap: 2rem;
		margin-right: 2rem;
	}
	p {
		font-weight: 600;
		margin-bottom: 0;
	}
}
