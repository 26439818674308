.modal__overlay {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: rgba(0, 0, 0, 0.75);
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 999;
}

.modal__container {
	background-color: #fff;
	max-width: 70vw;
	max-height: 100vh;
	padding: 2rem;
	border-radius: 4px;
	overflow-y: auto;
	box-sizing: border-box;
}

.modal__header {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.modal__title {
	margin-top: 0;
	margin-bottom: 0;
	font-weight: 600;
	font-size: var(--font-size--h3);
	line-height: 1.25;
	color: var(--theme-color);
	box-sizing: border-box;
}

.modal__close {
	background: transparent;
	border: 0;
}

.modal__header .modal__close:before {
	content: "\2715";
}

.modal__content {
	line-height: 1.5;
	padding-top: 2rem;
	color: rgba(0, 0, 0, 0.8);
	//   position: fixed;
	//   top: 10%;
	//   left: 45%;
	//   max-width: 300px;
	//   z-index: 1000;
	background-color: white;
}

.modal__buttons {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0.25rem 0.5rem;
	background-color: var(--theme-color);
	> div {
		color: white;
		text-decoration: none;
		font-weight: bold;

		&:hover {
			background-color: var(--theme-color--dark);
			cursor: pointer;
			color: white;
		}
	}
}

.modal__footer {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	gap: 1rem;
	text-transform: uppercase;
}

.modal__btn {
	font-size: 0.875rem;
	padding-left: 1rem;
	padding-right: 1rem;
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
	background-color: #e6e6e6;
	color: rgba(0, 0, 0, 0.8);
	border-radius: 0.25rem;
	border-style: none;
	border-width: 0;
	cursor: pointer;
	-webkit-appearance: button;
	text-transform: none;
	overflow: visible;
	line-height: 1.15;
	margin: 0;
	will-change: transform;
	-moz-osx-font-smoothing: grayscale;
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
	-webkit-transform: translateZ(0);
	transform: translateZ(0);
	transition: -webkit-transform 0.25s ease-out;
	transition: transform 0.25s ease-out;
	transition: transform 0.25s ease-out, -webkit-transform 0.25s ease-out;
}

.modal__btn:focus,
.modal__btn:hover {
	-webkit-transform: scale(1.05);
	transform: scale(1.05);
}

.modal__btn-primary {
	background-color: var(--theme-color--secondary);
	color: #fff;
}

/**************************\
  Demo Animation Style
\**************************/
@keyframes mmfadeIn {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

@keyframes mmfadeOut {
	from {
		opacity: 1;
	}
	to {
		opacity: 0;
	}
}

@keyframes mmslideIn {
	from {
		transform: translateY(15%);
	}
	to {
		transform: translateY(0);
	}
}

@keyframes mmslideOut {
	from {
		transform: translateY(0);
	}
	to {
		transform: translateY(-10%);
	}
}

.micromodal-slide {
	display: none;
}

.micromodal-slide.is-open {
	display: block;
}

.micromodal-slide[aria-hidden="false"] .modal__overlay {
	animation: mmfadeIn 0.3s cubic-bezier(0, 0, 0.2, 1);
}

.micromodal-slide[aria-hidden="false"] .modal__container {
	animation: mmslideIn 0.3s cubic-bezier(0, 0, 0.2, 1);
}

.micromodal-slide[aria-hidden="true"] .modal__overlay {
	animation: mmfadeOut 0.3s cubic-bezier(0, 0, 0.2, 1);
}

.micromodal-slide[aria-hidden="true"] .modal__container {
	animation: mmslideOut 0.3s cubic-bezier(0, 0, 0.2, 1);
}

.micromodal-slide .modal__container,
.micromodal-slide .modal__overlay {
	will-change: transform;
}

.modal {
	.speaker__intro {
		display: flex;
		align-items: center;
		gap: 1rem;
		line-height: 1;
		h3,
		p {
			margin-bottom: 0;
		}
	}
	.speaker__jobDetails {
		display: flex;
		align-items: center;
		margin: 1rem 0;
		border-radius: 4px;
		background-color: #ededed;
		padding: 0.75rem 1rem;
		font-size: var(--font-size--small);
	}
	.speaker__position {
		font-weight: 600;
		padding-right: 1rem;
		margin-right: 1rem;
		border-right: 2px solid #4a4a4a;
	}
	.speaker__firm {
		font-size: var(--font-size--small);
		color: var(--theme-color--secondary);
		&:hover {
			color: var(--theme-color);
		}
	}
}
