.eventContainer {
	display: grid;
	grid-template-columns: repeat(12, 1fr);
	.eventVideo {
		grid-column: span 12;
		@media screen and (min-width: 1024px) {
			grid-column: span 8;
		}
		.embedWrapper {
			position: relative;
			padding-bottom: 59.25%; /* 16:9 */
			padding-top: 0px;
			height: 0;
		}
	}
	.eventChat {
		grid-column: span 12;
		@media screen and (min-width: 1024px) {
			grid-column: span 4;
		}
		.embedWrapper {
			position: relative;
			padding-bottom: 100%; /* 16:9 */
			padding-top: 83px;
			height: 0;
		}
	}

	.embedWrapper iframe {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}
