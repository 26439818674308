:root {
	font-size: 62.5%; /* (62.5/100) * 16px = 10px */
	--font-size--smaller: 0.9rem;
	--font-size--small: 1rem;
	--font-size--default: 1.4rem;
	--font-size--large: 1.8rem;
	--font-size--larger: 2rem;
	--font-size--h1: 2.4rem;
	--font-size--h2: 2rem;
	--font-size--h3: 1.8rem;
	--font-size--h4: 1.4rem;
	@media screen and (min-width: 769px) {
		--font-size--smaller: 1.4rem;
		--font-size--small: 1.6rem;
		--font-size--default: 1.75rem;
		--font-size--large: 3rem;
		--font-size--larger: 3.5rem;
		--font-size--h1: 5.25rem;
		--font-size--h2: 3rem;
		--font-size--h3: 2.25rem;
		--font-size--h4: 2rem;
	}
	@media screen and (min-width: 1441px) {
		--font-size--smaller: 1.4rem;
		--font-size--small: 2rem;
		--font-size--default: 2.25rem;
		--font-size--large: 3rem;
		--font-size--larger: 3.5rem;
		--font-size--h1: 6rem;
		--font-size--h2: 4rem;
		--font-size--h3: 3.25rem;
		--font-size--h4: 2.5rem;
	}
}

a {
	color: var(--theme-color--darker);
	font-weight: 600;
	font-size: var(--font-size--default);
	@include sans-serif();
	&:hover {
		color: var(--theme-color--dark);
		text-decoration: none;
	}
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-weight: 600;
	margin-bottom: 2rem;
}

p {
	font-size: var(--font-size--default);
}

h1 {
	font-size: var(--font-size--h1);
}

h2 {
	font-size: var(--font-size--h2);
}

h3 {
	font-size: var(--font-size--h3);
}

h4 {
	font-size: var(--font-size--h4);
}

h5 {
	font-size: 1.75rem;
}

h6 {
	font-size: 1.25rem;
}

p {
	margin-bottom: 1.5rem;
	line-height: 1.75;
}

.lead {
	font-size: var(--font-size--larger);
	max-width: 900px;
	margin-bottom: 2rem;
	@media screen and (min-width: 769px) {
		margin-bottom: 5rem;
	}
	a,
	span {
		font-size: var(--font-size--larger);
	}
}

ol,
ul {
	@media screen and (min-width: 1025px) {
		font-size: var(--font-size--default);
	}
	li {
		margin-bottom: 0.5rem;
		line-height: 1.75;
	}
}

p + ul,
p + ol {
	margin-top: -0.5rem;
}

.footnote {
	background-color: white;
	padding: 1rem;
	font-size: var(--font-size--small);
	font-style: italic;
	@include shadow();
	border: 4px;
}

a {
	color: var(--theme-color--darker);
	font-weight: bold;
	text-decoration: none;
}

.btn {
	font-size: var(--font-size--default);
	border-radius: 4px;
	font-weight: 600;
	padding: 1.5rem;

	&-primary {
		background-color: var(--theme-color);
		border-color: var(--theme-color);
		&:hover {
			background-color: var(--theme-color--light);
			border-color: var(--theme-color--light);
		}
	}
}
