.abi-footer {
	font-size: var(--font-size--default);
	justify-content: space-between;
	@include outer_gutters;
	color: white;
	address {
		font-style: normal;
	}
	background-color: var(--theme-color--dark);

	font-weight: 500;
	padding: 4rem 2rem;
	@media screen and (min-width: 769px) {
		display: grid;
		grid-template-columns: repeat(6, 1fr);
		gap: 1rem;
	}
	svg {
		margin-right: 8px;
	}
	a {
		color: white;
		text-decoration: none;
		display: inline-block;
		font-size: var(--font-size--smaller);

		&:not(:last-of-type) {
			margin-right: 1rem;
		}

		&:hover {
			color: var(--theme-color--secondary);
		}
	}

	&__info {
		display: flex;
		grid-column: span 4;
		grid-row: 2;
	}

	&__address {
		padding: 1.5rem 0;
		font-size: var(--font-size--small);

		ul {
			list-style-type: none;
			padding-left: 0;
			margin-left: 0;

			li {
				margin-bottom: 0.25rem;
			}
		}
	}

	&__links {
		li {
			margin-bottom: 0.25rem;
		}
	}

	&__social-media {
		grid-column: span 2;
		grid-row: 2;
		display: flex;
		align-items: flex-start;
		flex-direction: column;
		justify-content: center;
		@media screen and (min-width: 769px) {
			align-items: flex-end;
		}

		div {
			padding-bottom: 0.5rem;
			border-bottom: 4px solid white;
			margin-bottom: 0.5rem;
			text-align: right;
		}

		nav {
			display: flex;
			background-color: var(--theme-color-darker);
			justify-content: flex-end;
			a {
				font-size: var(--font-size--large);
			}
		}
	}
	.help {
		background: white;
		color: var(--theme-color--darker);
		padding: 0.25rem 0.75rem;
		border-radius: 4px;
		margin: 1rem 0;
		font-size: var(--font-size--smaller);
		text-transform: uppercase;
	}
}

.non-profit-statement {
	font-size: var(--font-size--smaller);
	color: white;
	text-shadow: 1px 1px 1px var(--a6);
	text-align: center;
}
