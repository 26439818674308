.detailsWrapper {
	display: grid;
	grid-template-columns: repeat(12, 1fr);
	grid-column-gap: 1rem;
	background: bottom/cover no-repeat url("/assets/background.jpg");
	padding: 4rem 0 6rem 0;
	h1 {
		font-size: var(--font-size--h1);
		color: white;
		padding: 2rem;
		margin-bottom: 0;
		.sub-title {
			font-size: var(--font-size--h3);
			text-transform: uppercase;
			font-weight: 400;
		}
	}
	h2 {
		font-size: var(--font-size--h2);
		color: white;
	}
	.details {
		grid-column: span 12;
		@media screen and (min-width: 1024px) {
			grid-column: span 8;
		}

		.materials li {
			border-left: 4px solid var(--theme-color--secondary);
			padding: 1rem;
			margin-bottom: 2rem;
			div {
				font-weight: 600;
			}
			a {
				color: var(--theme-color--secondary);
				margin-right: 2rem;
				&:hover {
					color: var(--theme-color);
				}
			}
		}
	}
	.tab-list {
		display: flex;
		gap: 2rem;
		align-items: center;
	}
	.tabs {
		background-color: white;
		padding: 2rem;
		margin: 0 2rem;
		min-height: 515px;
		border-radius: 4px;
		@include shadow();
		li a[aria-selected="true"] {
			color: var(--theme-color--secondary);
			padding-bottom: 0.5rem;
			border-bottom: 4px solid var(--theme-color--secondary);
		}
	}
	.speakers {
		grid-column: span 12;
		padding-top: 2.5rem;
		margin: 0 2rem;

		@media screen and (min-width: 1024px) {
			padding-top: 8.5rem;
			grid-column: span 4;
		}
		&__list {
			background-color: white;
			padding: 1rem;
			margin: 0;
			border-radius: 4px;
			@include shadow();
			display: flex;
			flex-direction: column;
			flex-wrap: wrap;
		}
		.speaker {
			display: grid;
			grid-template-columns: repeat(3, 1fr);
			gap: 1rem;
			padding: 0.5rem 1rem;
			text-align: left;

			border: 0;
			background-color: transparent;
			&:hover {
				background-color: #ededed;
				cursor: pointer;
			}
			img {
				grid-column: span 1;
				margin: 0 auto;
			}
			&__intro {
				display: flex;
				align-items: center;
				gap: 1rem;
			}
			&__details {
				grid-column: span 2;
			}
			&__name {
				font-weight: 800;
				color: var(--theme-color--dark);
			}
			&__position {
				grid-column: span 2;
				font-weight: 400;
				font-size: var(--font-size--smaller);
			}
			&__firm {
				grid-column: span 2;
				color: var(--light-gray);
				font-style: italic;
				font-weight: 600;
				font-size: var(--font-size--smaller);
			}
		}
	}
}
